
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import AutomationCartActions from './AutomationCartActions.vue'

@Component({ components: { AutomationCartActions } })
export default class AutomationCart extends VueStrong {
  @Prop({ default: null }) group?: 'Essential' | 'Scale' | 'Pause' | 'Time' | 'Optimize' | 'Other'
  @Prop({ required: true }) automation!: yakkyo.IFacebookAutomation | any // remove any once the schema is refactored

  get bgColor() {
    switch (this.group) {
      case 'Essential':
        return '#FEFCF3'
      case 'Scale':
        return '#F9F9F9'
      case 'Pause':
        return '#EFEFEF'
      case 'Time':
        return '#FEFBF3'
      case 'Optimize':
        return '#FDF6EC'
      case 'Other':
        return '#F0ECE3'
      default:
        return ''
    }
  }

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount : null
  }

  get currency() {
    if (!this.selectedAdAccount) return 'USD'
    return this.selectedAdAccount.currency || 'USD'
  }

  automationRedirect(id: string) {
    this.$router.push({ name: 'Automation', params: { id, public: 'false' } })
  }
}
