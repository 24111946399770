
import { Component } from 'vue-property-decorator'
import AutomationCart from '@/modules/automation/components/AutomationCart.vue'
import ListsMixin from '@/modules/insight/mixins/ListsMixin.vue'

@Component({ components: { AutomationCart }, mixins: [ListsMixin] })
export default class AutomationList extends ListsMixin {
  activeTab = 0

  listDataAction = 'automation/list'
  setParamsAction = 'automation/setParams'

  get accountId() {
    return this.$store.state.account.adAccount?.id
  }

  get automations() {
    return this.$store.state.automation.automations.reduce((acc, i) => {
      acc[i.category.toLowerCase()] = acc[i.category.toLowerCase()] || []
      acc[i.category.toLowerCase()].push(i)
      return acc
    }, {})
  }

  get params() {
    return this.$store.state.automation?.params || {}
  }

  get categories() {
    return [
      { color: 'orange', name: 'essential' },
      { color: '#800000', name: 'scale' },
      { color: '#884ea0', name: 'pause' },
      { color: '#0e1458', name: 'time' },
      { color: '#2e4053', name: 'optimize' },
      { color: '#fa8072', name: 'other' }
    ]
  }

  get userCanAccess() {
    if (!this.user) return false
    return this.user.privileges! > 1 || (this.user.additionalScopes || []).includes('marketing-automations')
  }

  get user() {
    return this.$store.state.account.user
  }

  get dummyAutomations() {
    return {
      essential: [{ category: 'essential' }],
      scale: [{ category: 'scale' }],
      pause: [{ category: 'pause' }],
      time: [{ category: 'time' }],
      optimize: [{ category: 'Optimize' }],
      other: [{ category: 'other' }]
    }
  }
}
