
import { Component, Prop, Watch } from 'vue-property-decorator'
import FiltersMixin from '@/modules/insight/mixins/FiltersMixin.vue'

@Component
export default class AutomationFilters extends FiltersMixin {
  @Prop({ default: 0 }) tab!: number

  setFilterAction = 'automation/setParams'
  getAction = 'automation/list'

  get categories() {
    return ['Essential', 'Scale', 'Pause', 'Time', 'Optimize', 'Other']
  }

  get tags() {
    return this.$store.state.automation.automations.reduce((acc, automation) => {
      automation.tags.forEach(tag => {
        if (!acc.includes(tag)) acc.push(tag)
      })
      return acc
    }, [])
  }

  get params() {
    return this.$store.state.automation.params
  }

  get pageCount() {
    return Math.ceil(this.params.serverItemsLength / this.params.itemsPerPage) || 0
  }

  @Watch('tab')
  onTabChange() {
    this.updateParams({ ...this.params, tab: this.tab, page: 1 })
  }

  updateNameFilter = (name: string) => {
    this.updateParams({ ...this.params, name })
  }
  updateCategoryFilter = (category: string) => {
    this.updateParams({ ...this.params, category })
  }
  updateTagsFilter = (tags: string[]) => {
    this.updateParams({ ...this.params, tags })
  }
}
