
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class AutomationCartActions extends VueStrong {
  @Prop({ required: true }) automation!: yakkyo.IFacebookAutomation | any
  loading = false
  deleteDialog = false

  statusMenu = false
  deleteMenu = false

  async onStatusChange() {
    try {
      this.loading = true
      await this.$store.dispatch('automation/update', {
        id: this.automation._id,
        automation: { enabled: !this.automation.enabled }
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }

  async deleteAutomation() {
    try {
      this.loading = true
      await this.$store.dispatch('automation/remove', this.automation._id)

      this.deleteDialog = false
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }
}
