
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import AutomationCart from '@/modules/automation/components/AutomationCart.vue'
import AutomationFilters from '@/modules/automation/components/AutomationFilters.vue'
import AutomationList from '@/modules/automation/components/AutomationList.vue'
import ComingSoonBanner from '@/components/ComingSoonBanner.vue'

@Component({ components: { AutomationCart, AutomationFilters, AutomationList, ComingSoonBanner } })
export default class Automations extends VueStrong {
  activeTab = 0
  loading = false
  isIntersecting = false

  get params() {
    return this.$store.state.automation?.params
  }

  get userCanAccess() {
    if (!this.user) return false
    return this.user.privileges! > 1 || (this.user.additionalScopes || []).includes('marketing-automations')
  }

  get user() {
    return this.$store.state.account.user
  }

  created() {
    this.activeTab = this.params.tab || 0
  }
}
